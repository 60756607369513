@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@layer base {
  html {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

/* community bg dynamic colours */
.animated-background {
  background-size: 400%;

  -webkit-animation: animation 8s ease infinite;
  -moz-animation: animation 8s ease infinite;
  animation: animation 8s ease infinite;
}

@keyframes animation {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

/* colour bg of header when clicking on a specific page */
.active {
  background: #a570d3;
  -webkit-background-clip: text;
  color: transparent;
  text-fill-color: transparent;
}

/* contribution card show/hide */
/* .hover\:parent-hover:hover .hidden-text {
  display: block;
  transform: translatey(0);
}

.hidden-text {
  display: none;
} */

/* Burger Bar*/
.burger-menu {
  height: 50%;
  width: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  z-index: 50;
}

.burger-bar {
  width: 1.3rem;
  height: 0.25em;
  background-color: rgb(102, 100, 100);
  border-radius: 0.1em;
  z-index: 50;
}

.burger-bar-white {
  width: 1.3rem;
  height: 0.25em;
  background-color: rgb(102, 100, 100);
  border-radius: 0.1em;
  z-index: 50;
}

.menu {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 30;
  transition: all 0.3s ease;
}

/* ------------- sliding menu ------------------ */
.hidden {
  display: none;
}

.visible {
  display: inherit;
}

/* clicked */
.burger-bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0.15rem, 0.85rem) scale(120%);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3) {
  transform: rotate(135deg) translate(0.15rem, 0.55rem) scale(120%);
  transition: ease-out 0.5s;
}

.burger-bar-white.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0.15rem, 0.85rem) scale(120%);
  transition: ease-out 0.5s;
}

.burger-bar-white.clicked:nth-child(2) {
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar-white.clicked:nth-child(3) {
  transform: rotate(135deg) translate(0.15rem, 0.55rem) scale(120%);
  transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  background-color: rgb(102, 100, 100);
}

.burger-bar-white.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  background-color: white;
}
